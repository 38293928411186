.personal-center .user {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.personal-center img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 40px;
}

.personal-center .user-info{
    text-align: left;
    margin-top: 10px;
    font-size: 25px;
}
